import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import RestorePasswordComponent from "../components/RestorePasswordComponent";
import { restorePassword } from "services/user"
import queryString from 'query-string'

const RestorePassword = (props) => {
    const { history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const submit = async (values) => {
        setLoading(true);

        const queryValues = queryString.parse(props.location.search)
        let urlToken = queryValues.token;


        const data = {
            password: values.password
        }

        try {
            await restorePassword(data, urlToken);
            setSuccess(true);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    return (<RestorePasswordComponent
        {...props}
        submit={submit}
        loading={loading}
        success={success}
        error={error}
        history={history}
    />);
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(RestorePassword));