import React, { useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Modal from 'react-modal';
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import { Formik, Form } from "formik";
import moment from 'moment'
import './SuggestionsComponent.scss';
import { FiSearch } from "react-icons/fi";

const SuggestionsComponent = (props) => {
    const { 
        suggestions, 
        paginationTotal,
        paginationCurrent,
        paginationStep,
        paginationUrl, 
    } = props;

    const [optionsOpen, setOptionsOpen] = useState(null);
    const [selectedSuggestion, setSelectedSuggestion] = useState({});

    const selectSuggestion = (suggestion) => {
        setSelectedSuggestion(suggestion);
        setOptionsOpen(true);
    }

    return (
        <DashboardLayout>
            <div className="suggestions-wrapper">
                <div className="section-title">
                    <h1>Peticiones y Sugerencias</h1>
                </div>

                <div class="pagination-top">
                    <PaginationNavigator 
                        total={paginationTotal}
                        current={paginationCurrent}
                        step={paginationStep}
                        url={paginationUrl}
                    />
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Usuario</th>
                            <th>Asunto</th>
                            <th>Mensaje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            suggestions.length ?
                                suggestions.map((suggestion, i) => (
                                    <tr key={i}>
                                        <td>{moment(suggestion.createdAt).format('DD/MM/YY')}</td>
                                        <td>{suggestion.name} {suggestion.surname}</td>
                                        <td>{suggestion.subject}</td>
                                        <td>{suggestion.bodyTruncated}</td>
                                        <td>
                                            <div onClick={() => selectSuggestion(suggestion)} className="search-icon">
                                                <FiSearch />
                                            </div>
                                        </td>
                                    </tr>
                                )) :

                                <div>No hay sugerencias registradas para mostrar.</div>
                        }
                    </tbody>
                </table>

                <div class="pagination-bottom">
                    <PaginationNavigator 
                        total={paginationTotal}
                        current={paginationCurrent}
                        step={paginationStep}
                        url={paginationUrl}
                    />
                </div>

            </div>

            <Modal
                isOpen={optionsOpen}
                onRequestClose={() => setOptionsOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>Peticiones y Sugerencias</div>
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={{
                            date: moment(selectedSuggestion.createdAt) || '',
                            name: `${selectedSuggestion.name || ""} ${selectedSuggestion.surname || ""}` || '',
                            subject: selectedSuggestion.subject || '',
                            body: selectedSuggestion.body || '',
                        }}
                        onSubmit={(values) => { }}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="text"
                                            id="name"
                                            label="Nombre"
                                            placeholder="Nombre"
                                            disabled
                                        />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="text"
                                            id="subject"
                                            label="Asunto"
                                            placeholder="Asunto"
                                            disabled />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="textarea"
                                            id="body"
                                            label="Mensaje"
                                            multiline
                                            rows={5}
                                            placeholder="Mensaje"
                                            disabled />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {/* <Button className="light" onClick={() => setIsManageModalUserOpen(false)}>Cancelar</Button> */}
                                    <Button type='submit' onClick={() => setOptionsOpen()} >Confirmar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </DashboardLayout >
    );
}

export default SuggestionsComponent;
