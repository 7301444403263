import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getAllVideosService = async () => {
    try {
        let response = await customFetch(`${endpoints.youtube}/admin/all-videos`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getGetAllVideosService = async () => {
    try {
        let response = await customFetch(`${endpoints.youtube}/admin/getAllVideos`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getGetLatestVideosService = async () => {
    try {
        let response = await customFetch(`${endpoints.youtube}/admin/getAllVideos`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getHiddenVideosService = async () => {
    try {
        let response = await customFetch(`${endpoints.youtube}/admin/hidden-videos`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createHiddenVideosService = async ({videoData}) => {
    try {
        let response = await customFetch(`${endpoints.youtube}/hidden-videos`, {
            method: "POST",
            bodyReq: { videoData },
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
