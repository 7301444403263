import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import RecoverPasswordComponent from "../components/RecoverPasswordComponent";
import { recoverPassword } from "services/user"

const RecoverPassword = (props) => {
    const { history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const submit = async (values) => {
        setLoading(true);

        const data = {
            email: values.email,
        }

        try {
            await recoverPassword(data);
            setSuccess(true);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    return (<RecoverPasswordComponent
        {...props}
        submit={submit}
        loading={loading}
        error={error}
        success={success}
        history={history}
    />);
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(RecoverPassword));