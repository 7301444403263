import React, { useEffect, useState } from "react";
import NotificationComponent from "../components/EventsComponent";

const Events = (props) => {
    useEffect(() => {}, []);

    return <NotificationComponent />;
};

export default Events;
