import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import './RestorePasswordComponent.scss';

const RestorePasswordComponent = (props) => {
    const { loading, submit, error, success } = props;

    return (
        <AuthLayout>
            <div className="login-component">
                <Formik
                    initialValues={{ password: "", repeat_password: "" }}
                    validationSchema={yup.object().shape({
                        password: yup
                            .string()
                            .min(6, "Mínimo 6 caracteres")
                            .required("Campo obligatorio"),
                        repeat_password: yup
                            .string()
                            .min(6, "Mínimo 6 caracteres")
                            .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden")
                            .required("Campo obligatorio"),
                    })}
                    onSubmit={(values) => submit(values)}
                >
                    {(formik) => (
                        <>
                            <Form>

                                <h2>Confirma tu nueva contraseña</h2>

                                <MaterialInput
                                    id="password"
                                    formik={formik}
                                    type="password"
                                    label="Contraseña*"
                                    placeholder="Escribe aquí tu contraseña"
                                />

                                <MaterialInput
                                    id="repeat_password"
                                    formik={formik}
                                    type="password"
                                    label="Confirmar contraseña*"
                                    placeholder="Escribe aquí tu contraseña"
                                />


                                <Button
                                    loading={loading}
                                    type="submit"
                                    onClick={formik.handleSubmit}>
                                    Guardar contraseña
                                </Button>

                                {
                                    error ?
                                        <div className="error-message-global">Error al modificar la contraseña.</div> :
                                        null
                                }
                                {
                                    success ?
                                        <div className="success-message-global">Se ha modificado la contraseña con éxito.</div> :
                                        null
                                }
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
}

export default RestorePasswordComponent;
