import React from "react";
import "./PaginationNavigator.scss";


const PaginationNavigator = (props) => {
    const { total = 1, current = 1, step = 10, url = '/', showing = true} = props;

    if(total==0) return null;

    let component = [];

    component.push(<span className="pagination-label" key={0}>Página: </span>);

    for(let i=1; i<=Math.ceil(total/step); i++) {
        if(current==i) {
            component.push(<span className='pagination-current' key={i}>{i}</span>);
        } else {
            component.push(<span className='pagination-option' key={i}><a href={ url + "" + i }>{i}</a></span>);
        }
    }

    return (
    <div className="pagination-container" style={{ visibility: (showing ? 'visible' : 'hidden') }}>
        {component}
    </div>
    );
};

export default PaginationNavigator;
