import React, { useState } from "react";
import {useHistory} from 'react-router-dom';
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import CourseCard from "components/elements/CourseCard/CourseCard";
import SkeletonCourseCard from "components/elements/SkeletonCourseCard/SkeletonCourseCard";
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { Formik, Form, FieldArray } from "formik";
import moment from "moment";
import * as yup from "yup";
import Modal from 'react-modal';

import _ from 'lodash';

import './CoursesComponent.scss';

const CoursesComponent = (props) => {
    const {
        courses,
        assistants,
        selectedCourse,
        setSelectedCourse,
        submitCourse,
        deleteCourse,
        submitCourseLoading,
        deleteCourseLoading,
        setIsManageModalCourseOpen,
        isManageCourseModalOpen,
        isAssistantsModalOpen,
        setIsAssistantsModalOpen,
        setIsDeleteModalOpen,
        isDeleteModalOpen,
        coursesFetching,
        uploadFile,
        paginationTotal,
        paginationCurrent,
        paginationStep,
        paginationUrl,
    } = props;

    const [uploadingPicture, setUploadingPicture] = useState(false);
    const history = useHistory();

    const handlePictureUpload = async (file) => {
        setUploadingPicture(true)
        try {
            const url = await uploadFile(file);
            return url;
        }
        catch (e) {
            console.log('e', e);
        }
        finally {
            setUploadingPicture(false)
        }
    }

    const attendantsCallback = (course) => {
        history.push(`/course-attendants/${course.courseId}`)
    }

    return (
        <DashboardLayout>
            <div className="courses-wrapper">
                <div className="section-title">
                    <h1>Cursos</h1>
                    <Button className="secondary small" onClick={() => {
                        setSelectedCourse({})
                        setIsManageModalCourseOpen(true)
                    }}>
                        <FaPlus />
                        <span>Añadir curso</span>
                    </Button>
                </div>
                <div class="pagination-top">
                <PaginationNavigator
                total={paginationTotal}
                current={paginationCurrent}
                step={paginationStep}
                url={paginationUrl}
                />
                </div>
                {
                    coursesFetching ?
                        _.times(3, (i) => (
                            <SkeletonCourseCard key={i} />
                        )) :
                        courses.length ?
                            courses.map((course, i) => (
                                <CourseCard
                                    key={i}
                                    title={course.name}
                                    date={moment(course.startDate).format('DD/MM') + ' - ' + moment(course.endDate).format('DD/MM')}
                                    description={course.details}
                                    places={course.places}
                                    attendantsClickCallback={() => attendantsCallback(course)}
                                    editClickCallback={() => {
                                        setSelectedCourse(course);
                                        setIsManageModalCourseOpen(true);
                                    }}
                                    deleteClickCallback={() => {
                                        setSelectedCourse(course);
                                        setIsDeleteModalOpen(true);
                                    }} />
                            )) :
                            <div>No hay cursos registrados para mostrar.</div>
                }

            <div class="pagination-bottom">
                <PaginationNavigator
                total={paginationTotal}
                current={paginationCurrent}
                step={paginationStep}
                url={paginationUrl}
                />
                </div>

            </div>


            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                contentLabel="Example Modal">
                <div className="delete-modal__wrapper">
                    <div className="modal-body">
                        <FaRegTrashAlt />
                        <h3>¿Eliminar {selectedCourse.name}?</h3>
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => setIsDeleteModalOpen(false)}>Cancelar</Button>
                        <Button
                            className="danger"
                            loading={deleteCourseLoading}
                            onClick={() => deleteCourse(selectedCourse.courseId)}>
                            Eliminar
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isAssistantsModalOpen}
                onRequestClose={() => setIsAssistantsModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>Asistentes</div>
                    </div>

                    <div className="modal-body">
                    <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellidos</th>
                            <th>Email</th>
                            <th>DNI</th>
                            <th>Teléfono</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (assistants) ? assistants.map((assistant, i) => (
                                    <tr key={i}>
                                        <td>{assistant?.name}</td>
                                        <td>{assistant?.surname}</td>
                                        <td>{assistant?.email}</td>
                                        <td>{assistant?.nif}</td>
                                        <td>{assistant?.phone}</td>
                                    </tr>
                                ))

                            :

                            <div>No hay confirmados para mostrar</div>

                        }
                    </tbody>
                </table>
                    </div>
                    <div className="modal-footer">
                        <Button className="light" onClick={() => setIsAssistantsModalOpen(false)}>Volver</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isManageCourseModalOpen}
                onRequestClose={() => setIsManageModalCourseOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>Añadir Curso</div>
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={{
                            name: selectedCourse.name || '',
                            // date: selectedCourse.date || '',
                            startDate: moment(selectedCourse.startDate).format('YYYY-MM-DD'),
                            endDate: moment(selectedCourse.endDate).format('YYYY-MM-DD'),
                            places: selectedCourse.places || '',
                            // sessions: selectedCourse.sessions || '',
                            details: selectedCourse.details || '',
                            content: selectedCourse.content || '',
                            price: selectedCourse?.paymentDetails?.quantity || null,
                            picture: selectedCourse.image || {},
                            conditionalPaymentDetails: selectedCourse.conditionalPaymentDetails || []
                        }}
                        validationSchema={
                            yup.object().shape({
                                name: yup.string().required(),
                                startDate: yup.date().required(),
                                endDate: yup.date().required(),
                                places: yup.number().required(),
                                // sessions: yup.number().required(),
                                details: yup.string().required(),
                                content: yup.string().required(),
                                price: yup.number().required(),
                                picture: yup.object().shape({
                                    url: yup.string(),
                                    name: yup.string(),
                                    mimeType: yup.string()
                                })
                            })
                        }
                        onSubmit={(values) => submitCourse(values, selectedCourse?.courseId)}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">

                                    <div className=" fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="image"
                                            id="picture"
                                            uploadFile={handlePictureUpload}
                                            loading={uploadingPicture}
                                        />
                                    </div>

                                    <div className="fill-3">

                                    {
                                        (assistants.length>0) ?
                                            <Button onClick={() => setIsAssistantsModalOpen(true)}>Ver asistentes</Button>
                                        :
                                            <div>No hay asistentes confirmados</div>
                                    }

                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="text"
                                            id="name"
                                            label="Título Curso"
                                            placeholder="Escribe aquí el titulo del curso" />
                                    </div>

                                    <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="number"
                                            id="price"
                                            label="Precio"
                                            placeholder="Precio del curso" />
                                    </div>

                                    <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="number"
                                            id="places"
                                            label="Plazas"
                                            placeholder="Plazas disponibles" />
                                    </div>

                                    <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="date"
                                            id="startDate"
                                            label="Fecha de inicio" />
                                    </div>

                                    <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="date"
                                            id="endDate"
                                            label="Fecha de finalización" />
                                    </div>

                                    {/* <div className="input-wrapper fill-3">
                                        <MaterialInput
                                            formik={formik}
                                            type="number"
                                            id="sessions"
                                            label="Sesiones" />
                                    </div> */}

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type='quill'
                                            id="details"
                                            label="Descripción"
                                            placeholder="Escribe aquí la descripción del curso"
                                        />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type='quill'
                                            id="content"
                                            label="Contenido"
                                            placeholder="Escribe aquí el contenido del curso"
                                        />
                                    </div>

                                    <FieldArray
                                      name="conditionalPaymentDetails"
                                      render={arrayHelpers => (
                                        <div class="conditional-holder fill-row">
                                        {formik.values.conditionalPaymentDetails && formik.values.conditionalPaymentDetails.length > 0 ? (
                                          formik.values.conditionalPaymentDetails.map((friend, index) => (
                                            <div key={index} className="modal-body conditional-price">
                                            <div className="input-wrapper fill-3">
                                                <MaterialInput
                                                    formik={formik}
                                                    type="text"
                                                    id={`conditionalPaymentDetails[${index}].name`}
                                                    label="Nombre"
                                                    placeholder="Nombre del precio" />
                                            </div>
                                            <div className="input-wrapper fill-3">
                                                <MaterialInput
                                                    formik={formik}
                                                    type="text"
                                                    id={`conditionalPaymentDetails[${index}].priceId`}
                                                    label="Id"
                                                    placeholder="Identificador" />
                                            </div>
                                            <div className="input-wrapper fill-3">
                                                <MaterialInput
                                                    formik={formik}
                                                    type="number"
                                                    id={`conditionalPaymentDetails[${index}].quantity`}
                                                    label="Cantidad"
                                                    placeholder="Valor" />
                                            </div>
                                            <div className="input-wrapper fill-3">
                                                <MaterialInput
                                                    formik={formik}
                                                    type="select"
                                                    id={`conditionalPaymentDetails[${index}].requirement`}
                                                    label="Requisito"
                                                    options={[{value:"isClubMember",label:"isClubMember"},{value:"isStudent",label:"isStudent"}]} />
                                            </div>
                                            <Button
                                              className="danger"
                                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                              >
                                              -
                                              </Button>
                                              <Button
                                                onClick={() => arrayHelpers.insert(index, {name:'',priceId:'',quantity:null,requirement:null,recurrence:'one-time'})} // insert an empty string at a position
                                              >
                                                +
                                              </Button>
                                              </div>
                                            ))
                                          ) : (
                                            <Button onClick={() => arrayHelpers.push({name:'',priceId:'',quantity:null,requirement:null,recurrence:'one-time'})}>
                                            {/* show this when user has removed all friends from the list */}
                                            Añadir nuevo precio
                                            </Button>

                                          )}
                                            </div>
                                          )}
                                      />


                                </div>

                                <div className="modal-footer">
                                    <Button className="light" onClick={() => setIsManageModalCourseOpen(false)}>Volver</Button>
                                    <Button type='submit' onClick={() => formik.handleSubmit()} loading={submitCourseLoading}>{selectedCourse?.courseId ? 'Confirmar' : 'Añadir'}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </DashboardLayout >
    );
}

export default CoursesComponent;
