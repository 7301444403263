import React from "react";
import "./SuccessComponent.scss";
import Lottie from "react-lottie";
import animationData from "assets/lotties/ok.json";

const SuccessComponent = (props) => {

    return (
        <div>
            <div className="loading">
            <Lottie options={defaultOptions} height={150} width={150} />
            </div>
            <span className="text"> El pago se ha realizado correctamente. <br></br>Haga click en cancelar para volver a la aplicación </span>
        </div>
    );
};

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default SuccessComponent;
