import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getSuggestionsService = async () => {
    try {
        let response = await customFetch(`${endpoints.suggestions}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getSuggestionsPaginationService = async (args) => {
    try {
        const {limit = 10, skip = 0} = args;
        let response = await customFetch(`${endpoints.suggestions}?filter[limit]=${limit}&filter[skip]=${skip}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getSuggestionsTotalService = async () => {
    try {
        let response = await customFetch(`${endpoints.suggestions}/count`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};