import React, { useState } from "react";
import { FaEllipsisV, FaRegCalendar, FaRegUser, FaRegTrashAlt } from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai';
import OutsideClickHandler from 'react-outside-click-handler';
import ReactHtmlParser from 'react-html-parser';
import "./CourseCard.scss";

const CourseCard = (props) => {
    const { picture, title, date, description, places, sessions, deleteClickCallback, editClickCallback, attendantsClickCallback} = props;
    const [optionsOpen, setOptionsOpen] = useState(false);

    const courseCardCallback = (e) => {
        console.log("CARD CLICKED", e);
    }

    return (
        <div className="course-card">

            {
                picture &&
                <div className="picture">
                    <img src={picture} alt="" />
                </div>
            }

            <div className="main-content" onClick={attendantsClickCallback}>
                <div className="title">
                    <div className="course-title">{title}</div>
                    {
                        date &&
                        <div className="date">
                            <FaRegCalendar />
                            <span>{date}</span>
                        </div>
                    }
                </div>

                <div className="description">{ReactHtmlParser(description)}</div>
            </div>

            <div className="secondary-content" onClick={attendantsClickCallback}>
                {
                    places &&
                    <div className="places">
                        <FaRegUser />
                        <span>{places} plazas</span>
                    </div>
                }
                {
                    sessions &&
                    <div className="sessions">3 sesiones</div>
                }
            </div>

            <OutsideClickHandler onOutsideClick={(e) => setOptionsOpen(false)}>
                <div className="action">
                    <div className="dots-icon" onClick={() => setOptionsOpen(!optionsOpen)}>
                        <FaEllipsisV />
                    </div>

                    {
                        optionsOpen &&
                        <div className="options">
                            {
                                editClickCallback &&
                                <div className="option" onClick={editClickCallback}>
                                    <AiOutlineEdit size={'20px'} />
                                    <span>Editar</span>
                                </div>
                            }

                            <div className="option red" onClick={deleteClickCallback}>
                                <FaRegTrashAlt />
                                <span>Eliminar</span>
                            </div>
                        </div>
                    }
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default CourseCard;