import React from "react";
import "./Input.scss";

const InputComponent = (props) => {
    const { type, label, id, className, formik, placeholder } = props;
    let formikProps = {};

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onBlur: () => formik.setFieldTouched(id),
            onChange: (e) => formik.setFieldValue(id, e.target.value),
            error: formik.touched[id] && formik.errors[id],
        };
    }

    return (
        <div className={`input-component ${formikProps.error ? "error" : ""}`}>
            <label htmlFor={id}>{label}</label>
            {
                (type === 'text' || type === 'password' || type === 'date') &&
                <input
                    {...props}
                    type={type}
                    id={id}
                    name={id}
                    className={`${className} ${formikProps.error ? "error" : ""}`}
                    onBlur={formikProps.onBlur}
                    onChange={formikProps.onChange}
                    value={formikProps.value}
                    placeholder={placeholder}
                />
            }
            {
                type === 'textarea' &&
                <textarea
                    {...props}
                    id={id}
                    name={id}
                    className={`${className} ${formikProps.error ? "error" : ""}`}
                    onBlur={formikProps.onBlur}
                    onChange={formikProps.onChange}
                    value={formikProps.value}
                    placeholder={placeholder}
                />
            }
            {
                formikProps.error &&
                <span className="error-message">{formikProps.error}</span>
            }
        </div>
    );
};

export default InputComponent;
