import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
import Button from "components/elements/Button/Button";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import './RecoverPasswordComponent.scss';

const RecoverPasswordComponent = (props) => {
    const { loading, submit, error, success } = props;

    return (
        <AuthLayout>
            <div className="login-component">
                <Formik
                    initialValues={{ email: ""}}
                    validationSchema={yup.object().shape({
                        email: yup
                            .string()
                            .email("Formato inválido")
                            .required("Campo obligatorio"),
                    })}
                    onSubmit={(values) => submit(values)}
                >
                    {(formik) => (
                        <>
                            <Form>
                                <h2>Recuperación de la cuenta</h2>

                                <p>Ingresa tu correo electrónico. Enviaremos un enlace para recuperar tu cuenta.</p>
                                
                                <MaterialInput
                                    id="email"
                                    formik={formik}
                                    type="text"
                                    label="Email"
                                    placeholder="Escribe aquí tu e-mail"
                                />

                                <Button
                                    loading={loading}
                                    type="submit"
                                    onClick={formik.handleSubmit}>
                                    Enviar email
                                </Button>

                                {
                                    error ?
                                        <div className="error-message-global">Error al solicitar la recuperación.</div> :
                                        null
                                }

                                {
                                    success ?
                                        <div className="success-message-global">Se ha enviado la información al correo electrónico</div> :
                                        null
                                }
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </AuthLayout>
    );
}

export default RecoverPasswordComponent;
