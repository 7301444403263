import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

export const getNotificationsService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.adminNotifications}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getNotificationsPaginationService = async (args) => {
    try {
        const {limit = 10, skip = 0, order = "createdAt DESC"} = args;
        let response = await customFetch(`${endpoints.adminNotifications}?filter[limit]=${limit}&filter[skip]=${skip}&filter[order]=${order}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getNotificationsTotalService = async () => {
    try {
        let response = await customFetch(`${endpoints.notifications}/count`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createNotificationService = async ({title, body, screen, link, pending, createdAt}) => {
    try {
      let bodyReq = { title:title, body:body, screen:screen, link:link, pending:pending}
      if (pending){
        bodyReq.createdAt = (new Date(createdAt)).toISOString()
      }
        let response = await customFetch(`${endpoints.notifications}`, {
            method: "POST",
            bodyReq: bodyReq,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
export const updateNotificationService = async (id, data) => {
    try {
        let response = await customFetch(`${endpoints.notifications}/${id}`, {
            method: "PUT",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
export const deleteNotificationService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.notifications}/${id}`, {
            method: "DELETE",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
