import React, { useEffect, useState, useRef } from "react";
import ChatComponent from "../components/ChatsComponent"
import { chatLoginService, getContactsInformationService, getContactsService, getPrivateChatsService, getUnreadMessagesCount,  listenChatIdService, sendMessageService, createPrivateChat } from "../../../services/chat";

import { getLastReadLocalStorage, setLastReadLocalStorage, validURL } from "../../../services/utils";
import _, { trim } from "lodash";
import queryString from 'query-string';

const Chats = (props) => {

    const params = queryString.parse(props.location.search)
    const profileIdAdmin = params.type;

    const [chats, setChats] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [loadingChats, setLoadingChats] = useState(true);

    const [chatId, setChatId] = useState(undefined);
    const [chatName, setChatName] = useState(undefined);
    const [chatUserPhoto, setChatUserPhoto] = useState(undefined);
    const [chatType, setChatType] = useState(undefined);

    const [messages, setMessages] = useState([]);
    const messagesListened = useRef([]);
    const listener = useRef(null);

    //listeners
    const groupListener = useRef(null);
    const privateChatListener = useRef(null);
    const contactListener = useRef(null);
    //helpers
    const contactsInfo = useRef([]);
    const lastReadMessages = useRef(null);
    const activeChat = useRef(null);
    const chatsListened = useRef([]);
    const contactsListened = useRef([]);
    
    useEffect(() => {
        initializeChat();

        return () => {
              if(isFunction(privateChatListener.current)) privateChatListener.current();
        }
    }, [params.type]);

    

    function isFunction(functionToCheck) {
       return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    const initializeChat = async () => {
        try {
            chatsListened.current = []
            setChats([]);

            setChatId(undefined)
            setChatName(undefined)
            setChatUserPhoto(undefined)
            setChatType(undefined)

            await chatLoginService();
            getChats();
            getLastRead();
        }
        catch (e) {
            console.log('error initializing chat', e);
        }
        setLoadingChats(false)
    }
    
    const getChats = async () => {
        await getContactsInformation();
        //groupListener.current = await getGroupsService(profileIdAdmin, addChat);
        privateChatListener.current = await getPrivateChatsService(profileIdAdmin, addChat);
        //contactListener.current = await getContactsService(addContact);
    };
    
    const getLastRead = async () => {
        const lastRead = await getLastReadLocalStorage();
        lastReadMessages.current = lastRead;
    };

    const addChat = async (chat) => {
        let ignoreChat = false;
        const unreadMessages = await checkUserHasUnreadMessages(chat);
        chat.badge = unreadMessages;
        const index = _.findIndex(chatsListened.current, (c) => c.id === chat.id);

        if (index >= 0) {
            chatsListened.current.splice(index, 1);
        }
        if (chat.type !== "public") {
            const participants = chat.participants;
            const userToTalkTo = participants[0] === profileIdAdmin ? participants[1] : participants[0];
            const contactInformationIndex = _.findIndex(contactsInfo.current, (c) => c.profileId === userToTalkTo);
            if (contactInformationIndex >= 0) {
                chat = {
                    ...chat,
                    ...contactsInfo.current[contactInformationIndex],
                    photo: contactsInfo.current[contactInformationIndex] && contactsInfo.current[contactInformationIndex].image ? contactsInfo.current[contactInformationIndex].image.url : null,
                };
            } else ignoreChat = true;
        }
        if (!ignoreChat) {
            chatsListened.current.unshift(chat);
            chatsListened.current = _.orderBy(chatsListened.current, ({ updatedAt }) => updatedAt || 0, "desc");
            setChats([...chatsListened.current]);
        }
    };

    const checkUserHasUnreadMessages = async (chat) => {
        const chatId = chat.id;
        const lastReadTime = lastReadMessages.current[chatId];
        if (!lastReadTime || activeChat.current === chatId) {
            lastReadMessages.current[chat.id] = new Date().getTime();
            setLastReadLocalStorage(lastReadMessages.current);
            return 0;
        }

        const userIsUpdated = lastReadTime >= chat?.lastMessage?.timestamp;
        if (!userIsUpdated) {
            const count = await getUnreadMessagesCount(chatId, lastReadTime, chat.type);
            return count;
        }
        return 0;
    };


    const getContactsInformation = async () => {
        try {
            const response = await getContactsInformationService();
            // console.log(response)
            contactsInfo.current = response;
            return response;
        } catch (err) {
            console.log(err);
        }
    };

    const editChat = (chatId, data) => {
        const index = _.findIndex(chatsListened.current, (c) => c.id === chatId);
        if (index >= 0) {
            chatsListened.current[index] = { ...chatsListened.current[index], ...data };
        }
        setChats([...chatsListened.current]);
    };

    const goToChat = async (chat) => {
        let chatToGo = chat;
        try {
            if (chat.type === "contact") {
                //if it's a contact, not a chat
                chatToGo = await createPrivateChat(chat, profileIdAdmin);
                const participants = chatToGo.participants;
                const userToTalkTo = participants[0] === profileIdAdmin ? participants[1] : participants[0];
                const contactInformationIndex = _.findIndex(contactsInfo.current, (c) => c.profileId === userToTalkTo);
                chatToGo = {
                    ...contactsInfo.current[contactInformationIndex],
                    photo: contactsInfo.current[contactInformationIndex] && contactsInfo.current[contactInformationIndex].image ? contactsInfo.current[contactInformationIndex].image.url : null,
                    ...chatToGo,
                };
            }
            activeChat.current = chatToGo.id;
            editChat(chatToGo.id, { badge: 0 });
            lastReadMessages.current[chatToGo.id] = new Date().getTime();
            setLastReadLocalStorage(lastReadMessages.current);
        } catch (e) {
            console.log(e);
        } finally {
            setChatId(chatToGo.id)
            setChatName(chatToGo.name + " " + chatToGo.surname)
            setChatUserPhoto(chatToGo.photo)
            setChatType(chatToGo.type)
        }
    };


    useEffect(() => {
        getMessages();

        //remove listener when the user leaves the screen
        return () => listener?.current && listener.current();
    }, [chatId]);

    const addMessage = (message) => {
        const senderId = message.userId;
        const contactInformationIndex = _.findIndex(contactsInfo.current, (c) => c.profileId === senderId);
        if (contactInformationIndex !== undefined && contactsInfo.current[contactInformationIndex]) {
            message = {
                ...message,
                name: contactsInfo.current[contactInformationIndex].name,
                photo: contactsInfo.current[contactInformationIndex].image ? contactsInfo.current[contactInformationIndex].image.url : null,
            };
        }
        messagesListened.current.push(message);
        setMessages([...messagesListened.current]);
    };

    const getMessages = async () => {
        messagesListened.current = [];
        setMessages([])
        if (!chatId) return;
        listener.current = listenChatIdService(chatId, addMessage, chatType);
    };

    const sendMessage = (text) => {
        if(trim(text).length === 0) return;

        let typeText = "text";
        if(validURL(text)) typeText = 'url';

        const timestamp = new Date().getTime();
        const message = {
            name: "Fundación Sicomoro",
            timestamp,
            message: { type: typeText, content: text },
            userId: profileIdAdmin,
        };
        sendMessageService(chatId, message, chatType);
    };


    return <ChatComponent
                    loadingChats={loadingChats}
                    chats={chats}
                    goToChat={goToChat}
                    messages={messages}
                    sendMessage={sendMessage}
                    chatName={chatName}
                    chatUserPhoto={chatUserPhoto}
                    profileIdAdmin={profileIdAdmin}   
                    />

}

export default Chats;

