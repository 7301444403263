import React from "react";
import "./SearchBar.scss";


const SearchBar = ({input:keyword, onChange:setKeyword}) => {

    return (
        <div className="search-bar">
            <input className="search-bar-text" key="search-bar" value={keyword} placeholder={"Buscar..."} onChange={(e) => setKeyword(e.target.value)}/>
        </div>
    )
}

export default SearchBar;