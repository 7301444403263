import { endpoints } from "./endpoints";
import customFetch from "./customFetch";
import { getUserToken } from "./utils";

// Logins factory
const existingLogins = [
    // {
    //     id: 1,
    //     name: 'Admin',
    //     email: 'admin@pitagorinesgroup.com',
    //     password: 'admin',
    //     role: 'Admin'
    // },
    {
        id: 2,
        name: "User",
        email: "user@pitagorinesgroup.com",
        password: "user",
        role: "User",
    },
];

const authFactory = (credentials) => {
    const userExists = existingLogins.filter((existingLogin) => existingLogin.email === credentials.email)[0];

    if (userExists) {
        return {
            ...userExists,
            token: "123",
        };
    } else {
        throw "Wrong credentials";
    }
};

export const loginService = async (credentials) => {

    try {
        let response = await customFetch(`${endpoints.admin}/login`, {
            method: "POST",
            bodyReq: credentials,
        });

        if (response.error) throw response;

        // const userData = await getUserIdService(response.token);
        // const userInfo = await getUserInfoService(id, response.token);

        return { token: response.token };
    } catch (err) {
        throw err;
    }
};

export const registerService = async (credentials) => {
    return authFactory(credentials); // Remove this line when integrating with back-end

    try {
        let response = await customFetch(`${endpoints.user}/register`, {
            method: "POST",
            bodyReq: credentials,
        });

        if (response.error) throw response;

        const { id } = await getUserIdService(response.token);
        const userInfo = await getUserInfoService(id, response.token);

        return { ...userInfo, token: response.token };
    } catch (err) {
        throw err;
    }
};

export const getUserIdService = async (token) => {
    try {
        let response = await customFetch(`${endpoints.profiles}/me`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUserInfoService = async (id, token) => {
    try {
        let response = await customFetch(`${endpoints.user}/${id}`, {
            method: "GET",
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUsersService = async (profileId=null) => {

    let query = (profileId) ? `/?filter[where][profileId]=${profileId}`:'';

    try {
        let response = await customFetch(`${endpoints.profiles}${query}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return profileId ? response[0] : response;
    } catch (err) {
        throw err;
    }
};

export const getCarnetsService = async (profileId) => {
    try {
        let response = await customFetch(`${endpoints.carnets}/${profileId}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}


export const createCarnetService = async (data, profileId) => {
    console.log("USER CARNET SERVICE", data);
    try {
        let response = await customFetch(`${endpoints.carnets_admin}/${profileId}`, {
            method: "POST",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};


export const getUsersFiltersService = async (filters={}) => {

    let query = Object.entries(filters).length !== 0 ? `/?filter=${JSON.stringify(filters)}`:'';

    try {
        let response = await customFetch(`${endpoints.profiles}${query}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUsersPaginationService = async (args) => {
    try {
        const {limit = 10, skip = 0} = args;
        let response = await customFetch(`${endpoints.profiles}?filter[limit]=${limit}&filter[skip]=${skip}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const getUsersTotalService = async () => {
    try {
        let response = await customFetch(`${endpoints.profiles}/count`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};


export const recoverPassword = async (email) => {
    try {
        let response = await customFetch(`${endpoints.credentials}/get-reset-password-url`, {
            method: "POST",
            bodyReq: email,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const restorePassword = async (password, token) => {
    try {
        let response = await customFetch(`${endpoints.credentials}/reset-password`, {
            method: "POST",
            bodyReq: password,
            token,
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const deleteUserService = async (id) => {
    try {
        let response = await customFetch(`${endpoints.profiles}/admin/${id}`, {
            method: "DELETE",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createUserService = async (data) => {
    try {
        let response = await customFetch(`${endpoints.profiles}`, {
            method: "POST",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const adminCreateUserService = async (data) => {
    try {
        let response = await customFetch(`${endpoints.admin}/credentials/register`, {
            method: "POST",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const adminCreateUserCarnetService = async (profileId, data) => {
    try {
        let response = await customFetch(`${endpoints.admin}/carnets/${profileId}`, {
            method: "POST",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};




export const editUserService = async (data, userId) => {
    try {
        let response = await customFetch(`${endpoints.profiles}/admin/${userId}`, {
            method: "PUT",
            bodyReq: data,
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};
