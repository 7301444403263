import React, { useState } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import {useHistory} from 'react-router-dom';
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import CourseCard from "components/elements/CourseCard/CourseCard";
import SkeletonCourseCard from "components/elements/SkeletonCourseCard/SkeletonCourseCard";
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { Formik, Form } from "formik";
import moment from "moment";
import * as yup from "yup";
import Modal from 'react-modal';
import {confirmAssistanceService} from 'services/courses'

import _ from 'lodash';

import './CourseAttendantsComponent.scss';

const CoursesAttendantsComponent = (props) => {
    const {
        assistants,
        pendingAssistants,
        course,
        courseFetching
    } = props;

    const history = useHistory();
    console.log(course)
    const returnCoursesCallback = () => {
        history.push(`/cursos/`)
    }

    return (
        <DashboardLayout>
            <div className="courses-wrapper">
                <div className="section-title">
                    <h1>Participantes "{course.name}"</h1>
                    <Button className="secondary small" onClick={() => returnCoursesCallback()}>
                        <span>Volver</span>
                    </Button>
                </div>
                {
                    !courseFetching ?
                        assistants.length ?
                            <table className="attendants-course">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th>Email</th>
                                    <th>Teléfono</th>
                                </tr>
                                </thead>
                                <tbody>{
                                assistants.map((assistant, i) => (
                                <tr key={i}>
                                    <td>{assistant.name}</td>
                                    <td>{assistant.surname}</td>
                                    <td>{assistant.email}</td>
                                    <td>{assistant.phone}</td>
                                </tr>))
                                }</tbody>
                            </table> :
                            <div className="no-attendants">
                                <img src={require("assets/images/icon_noparticip.png")} alt="no-attendants" width="100px" />
                                <p>Aún no hay participantes en el curso.</p>
                            </div>
                        : <div className="attendants-loading"></div>
                }
            </div>
            <div className="courses-wrapper">
                <div className="section-title">
                    <h1>Pendientes de confirmar</h1>
                </div>
                {
                    !courseFetching ?
                        pendingAssistants.length ?
                            <table className="attendants-course">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th>Email</th>
                                    <th>Teléfono</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>{
                                pendingAssistants.map((assistant, i) => (
                                <tr key={i}>
                                    <td>{assistant.name}</td>
                                    <td>{assistant.surname}</td>
                                    <td>{assistant.email}</td>
                                    <td>{assistant.phone}</td>
                                    <td><Button onClick={()=>{
                                      confirmAssistanceService(course.courseId,assistant.profileId).then(()=>{
                                        returnCoursesCallback()
                                      })
                                    }}>Confirmar</Button></td>
                                </tr>))
                                }</tbody>
                            </table> :
                            <div className="no-attendants">
                                <img src={require("assets/images/icon_noparticip.png")} alt="no-attendants" width="100px" />
                                <p>No hay asistentes pendientes.</p>
                            </div>
                        : <div className="attendants-loading"></div>
                }
            </div>
        </DashboardLayout>
    );
}

export default CoursesAttendantsComponent;
