import React, { useState } from "react";
import { FormikTextField, FormikSelectField } from "formik-material-fields";
import { FaCamera, FaRegEye, FaRegEyeSlash, FaFilePdf } from 'react-icons/fa';
import animationData from "assets/lotties/loading-white.json";
import Lottie from "react-lottie";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import "./MaterialInput.scss";

const MaterialInput = (props) => {

    const { type, label, id, options, className, formik, uploadFile, loading, quillRef = null, onKeyDown = () => {}} = props;

    const [inputType, setInputType] = useState(type === 'date' ? 'text' : type);
    const [passwordVisible, setPasswordVisible] = useState(false)
    let hasErrors = false;

    let formikProps = {};

    if (formik) {
        formikProps = {
            value: formik.values[id],
            onChange: (e) => formik.setFieldValue(id, e.target.value),
            error: formik.touched[id] && formik.errors[id],
        };
        hasErrors = formik.touched[id] && formik.errors[id];
    }

    const handlePictureUpload = async (e) => {
        try {
            const file = e.target.files[0];
            const url = await uploadFile(file);
            formik.setFieldValue('picture', { url, name: file.name, mimeType: file.type })
        }
        catch (e) {
            console.log('e', e);
        }
    }

    const handlePdfUpload = async (e) => {
        try {
            const file = e.target.files[0];
            const url = await uploadFile(file);
            formik.setFieldValue('picture', { url, name: file.name, mimeType: file.type, file })
        }
        catch (e) {
            console.log('e', e);
        }
    }

    return (
        <div className="material-input-component">
            {
                (type === 'text' || type === 'textarea' || type === 'password' || type === 'number') &&
                <div className='input'>
                    <FormikTextField
                        {...props}
                        type={type}
                        id={id}
                        name={id}
                        label={label}
                        fullWidth
                        className={className}
                        InputLabelProps={{ style: { fontWeight: 600, letterSpacing: 0.5, opacity: 0.9 } }}
                        {...formikProps} />
                    {type === 'password' &&
                        <div onClick={() => { if (passwordVisible) { setInputType('password'); setPasswordVisible(false) } else { setInputType('text'); setPasswordVisible(true) } }} className='password-eye'>
                            {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                        </div>
                    }
                </div>
            }

            {
                ( type === 'date' || type === 'datetime-local') &&
                <div>
                <label className='input-label'>{label}</label>
                <div className='input'>
                    <FormikTextField
                        {...props}
                        type={type}
                        id={id}
                        name={id}
                        fullWidth
                        label=""
                        className={className}
                        InputLabelProps={{ style: { fontWeight: 600, letterSpacing: 0.5, opacity: 0.9 } }}
                        {...formikProps} />
                </div>
                </div>
            }

            {
                (type === 'select') &&
                <FormikSelectField
                    id={id}
                    name={id}
                    label={label}
                    options={options}
                    InputLabelProps={{ style: { fontWeight: 600, letterSpacing: 0.5, opacity: 0.9 } }}
                    fullWidth
                />
            }

            {
                (type === 'image') &&
                (
                    <div className={`${hasErrors ? 'input-error-formik' : ''}`}>
                        <div className='picture-wrapper'>
                            <label className='input-label'>Foto</label>
                            <label className={`picture ${loading ? 'disabled' : ''}`} for='picture'>
                                {
                                    loading ? <Lottie options={defaultOptions} height={40} width={40} /> :
                                        formik.values['picture'] && formik.values['picture'].url ?
                                            <img src={formik.values['picture'].url} alt="" /> : <FaCamera />
                                }
                            </label>
                            <input
                                style={{
                                    display: "none",
                                }}
                                type="file"
                                id='picture'
                                onChange={(event) => handlePictureUpload(event, formik)}
                            />
                        </div>
                    </div>
                )
            }

            {
                (type === 'pdf') &&
                (
                    <div className={`${hasErrors ? 'input-error-formik' : ''}`}>
                        <div className='picture-wrapper'>
                            <label className='input-label'>Pdf</label>
                            <label className={`picture ${loading ? 'disabled' : ''}`} for='picture'>
                                {
                                    loading ? <Lottie options={defaultOptions} height={40} width={40} /> :
                                        formik.values['picture'] && formik.values['picture'].url ?
                                        formik.values['picture'].name : <FaFilePdf />
                                }
                            </label>
                            <input
                                style={{
                                    display: "none",
                                }}
                                type="file"
                                id='picture'
                                onChange={(event) => handlePdfUpload(event, formik)}
                            />
                        </div>
                    </div>
                )
            }

            {
                (type === 'quill') &&
                (
                    <div className={`${hasErrors ? 'input-error-formik' : ''}`}>
                        <label className='input-label'>{label}</label>
                        <div style={{ marginBottom: 10 }} />
                        <ReactQuill theme="snow" value={formik.values[id]} ref={quillRef} onKeyDown={onKeyDown} onChange={(e) => formik.setFieldValue(id, e)} />
                    </div>)
            }

        </div >
    );
};


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default MaterialInput;
