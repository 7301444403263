import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

export const firebaseApp = firebase;
export const provider = new firebase.auth.FacebookAuthProvider();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const db = firebaseApp.firestore();