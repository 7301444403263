import React, { useEffect, useState } from "react";
import SuggestionsComponent from '../components/SuggestionsComponent';
import { getSuggestionsPaginationService, getSuggestionsTotalService } from 'services/suggestions';
import { getUsersService } from "services/user";

const Suggestions = (props) => {
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsFetching, setSuggestionsFetching] = useState(false);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationCurrent, setPaginationCurrent] = useState(1);
    const [paginationStep, setPaginationStep] = useState(10);
    const [paginationUrl, setPaginationUrl] = useState('/sugerencias/');

    useEffect(() => {
        getSuggestions();
    }, []);

    const getSuggestions = async () => {
        setSuggestionsFetching(true);

        try {

            //BEGIN Pagination

            const totalSuggestionsData = await getSuggestionsTotalService(); //CHANGE when copying

            let totalData = parseInt(totalSuggestionsData.count);

            //No payments, function should exit to save up resources.

            if(totalData==0) {
                setSuggestionsFetching(false); //CHANGE when copying
                return;
            }

            setPaginationTotal(totalData);

            //Check if pagenumber is ok, elsewhere it returns value to '1'

            let pageNumber = parseInt(props.location.pathname.split(paginationUrl)[1]);

            if(pageNumber > 1) {
                
                if(pageNumber*paginationStep-totalData >= paginationStep) pageNumber = 1;

            }

            if(pageNumber <= 0) pageNumber = 1;

            if(isNaN(pageNumber)) pageNumber = 1;

            setPaginationCurrent(pageNumber);

            let paginationOptions = {
                limit: paginationStep,
                skip: (pageNumber-1)*paginationStep,
            }


            const suggestions = await getSuggestionsPaginationService(paginationOptions);

            //const suggestions = await getSuggestionsService();
            const users = await getUsersService();
            const profilesMap = new Map();
            users.forEach(function (user) {
                profilesMap.set(user.profileId, user);
            });

            const characterLimit = 50;
            
            suggestions.forEach(function (suggestion) {
                suggestion.bodyTruncated = (suggestion.body.length > characterLimit ? suggestion.body.substring(0, characterLimit) + '...' : suggestion.body);
            })

            const formattedSuggestions = suggestions.map((suggestion) => {
                const profile = profilesMap.get(suggestion.profileId) || {};
                return {
                    ...suggestion,
                    name: profile.name,
                    surname: profile.surname
                }
            });

            setSuggestions(formattedSuggestions);
        } catch (err) {
            console.log(err);
        }

        setSuggestionsFetching(false);
    }



    return <SuggestionsComponent
        suggestions={suggestions}
        suggestionsFetching={suggestionsFetching}
        paginationTotal={paginationTotal}
        paginationCurrent={paginationCurrent}
        paginationStep={paginationStep}
        paginationUrl={paginationUrl}
    />
}

export default Suggestions;
