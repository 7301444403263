import React from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Button from "components/elements/Button/Button";
import PaginationNavigator from "components/elements/PaginationNavigator/PaginationNavigator";
import MaterialInput from "components/elements/MaterialInput/MaterialInput";
import { RiAddFill } from "react-icons/ri";
import { FiSearch, FiYoutube } from "react-icons/fi";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Modal from "react-modal";
import moment from "moment";
import "./YoutubeComponent.scss";

const YoutubeComponent = (props) => {
    const { 
        videos,
        videosFetching,
        setVideosFetching,
        isCreateVideoModalOpen,
        setIsCreateVideoModalOpen,
        isManageVideoModalOpen,
        setIsManageVideoModalOpen,
        submitVideoLoading, 
        setSubmitVideoLoading,
        selectedVideo,
        setSelectedVideo,
        createHiddenVideo
    } = props;

    const selectVideo = (video) => {
        setSelectedVideo(video);
        setIsManageVideoModalOpen(true);
    }

    return (
        <DashboardLayout>
            <div className="youtube-wrapper">
                <div className="section-title">
                    <h1>Vídeos Youtube privados</h1>
                    <Button className="secondary small" onClick={() => setIsCreateVideoModalOpen(true)}>
                        <RiAddFill />
                        <span>Añadir nuevo</span>
                    </Button>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Título</th>
                            <th>Descripión</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {videos.length ? (
                            videos.map((video, i) => (
                                <tr key={i}>
                                    <td>{video.videoData.snippet.title}</td>
                                    <td>{video.videoData.snippet.description}</td>
                                    <td>
                                        <div onClick={() => selectVideo(video)}  className="search-icon">
                                            <FiSearch />
                                        </div>
                                    </td> 
                                </tr>
                            ))
                        ) : (
                            <div className="empty">No hay vídeos para mostrar.</div>
                        )}
                    </tbody>
                </table>

                <Modal
                    isOpen={isCreateVideoModalOpen}
                    onRequestClose={() => setIsCreateVideoModalOpen(false)}
                    className="sicomoro-modal"
                    overlayClassName="modal-overlay with-background"
                    ariaHideApp={false}
                >
                    <div className="manage-course-modal__wrapper">
                        <div className="modal-header">
                            <div>Añadir nuevo vídeo</div>
                        </div>

                        <Formik
                            initialValues={{
                                id: "",
                                title: "",
                                description: ""
                            }}
                            validationSchema={yup.object().shape({
                                id: yup.string().required("El ID es obligatorio"),
                                title: yup.string().required("El titulo es obligatorio")
                            })}
                            onSubmit={(values) => {
                                createHiddenVideo(values);
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <div className="modal-body">
                                        <div className="input-wrapper fill-row">
                                            <MaterialInput formik={formik} type="text" id="id" label="ID" placeholder="Escribe aquí el ID de youtube" />
                                        </div>
                                        <div className="input-wrapper fill-row">
                                            <MaterialInput formik={formik} type="text" id="title" label="Título " placeholder="Escribe aquí el título del vídeo" />
                                        </div>
                                        <div className="input-wrapper fill-row">
                                            <MaterialInput formik={formik} type="textarea" multiline rows={5} id="description" label="Descripción" placeholder="Escribe aquí la descripción del vídeo" />
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        <Button className="light" onClick={() => setIsCreateVideoModalOpen(false)}>
                                            Cancelar
                                        </Button>
                                        <Button onClick={formik.handleSubmit} loading={submitVideoLoading}>
                                            Enviar
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal>

                <Modal
                isOpen={isManageVideoModalOpen}
                onRequestClose={() => setIsManageVideoModalOpen(false)}
                className="sicomoro-modal"
                overlayClassName="modal-overlay with-background"
                ariaHideApp={false}>
                <div className="manage-course-modal__wrapper">
                    <div className="modal-header">
                        <div>{selectedVideo?.videoData.snippet.title || ''}</div>
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={{
                            id: selectedVideo?.videoData.id || '',
                            description: selectedVideo?.videoData.snippet.description || '',
                        }}
                        onSubmit={(values) => { }}>
                        {(formik) => (
                            <Form>
                                <div className="modal-body">

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="text"
                                            id="id"
                                            label="ID"
                                            placeholder="ID"
                                            disabled />
                                    </div>

                                    <div className="input-wrapper fill-row">
                                        <MaterialInput
                                            formik={formik}
                                            type="textarea"
                                            id="description"
                                            label="Descripción"
                                            multiline
                                            rows={5}
                                            placeholder="Descripción"
                                            disabled />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {/* <Button className="light" onClick={() => setIsManageModalUserOpen(false)}>Cancelar</Button> */}
                                    <Button type='submit' onClick={() => setIsManageVideoModalOpen(false)} >Volver</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            </div>
        </DashboardLayout>
    );
};

export default YoutubeComponent;
