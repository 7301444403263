import React, { useEffect, useState } from "react";
import YoutubeComponent from "../components/YoutubeComponent";
import { getHiddenVideosService, createHiddenVideosService } from "services/youtube";

const Youtube = (props) => {
    const [videos, setVideos] = useState([]);
    const [videosFetching, setVideosFetching] = useState(false);
    const [isCreateVideoModalOpen, setIsCreateVideoModalOpen] = useState(false);
    const [isManageVideoModalOpen, setIsManageVideoModalOpen] = useState(false);
    const [submitVideoLoading, setSubmitVideoLoading] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState({videoData:{id:'',snippet:{title:'',description:''}}});

    /* TO DO PAGINATION
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [paginationCurrent, setPaginationCurrent] = useState(1);
    const paginationStep = 10;
    const paginationUrl = '/notificaciones/';*/
    /*
    const [deleteCourseLoading, setDeleteCourseLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);*/

    useEffect(() => {
        getVideos();
    }, []);

    const getVideos = async () => {
        setVideosFetching(true);

        try {

            /*
             //BEGIN Pagination

             const totalYoutubeData = await getYoutubeTotalService(); //CHANGE when copying

             let totalData = parseInt(totalYoutubeData.count);
 
             //No payments, function should exit to save up resources.
 
             if(totalData==0) {
                 setNotificationFetching(false); //CHANGE when copying
                 return;
             }
 
             setPaginationTotal(totalData);
 
             //Check if pagenumber is ok, elsewhere it returns value to '1'
 
             let pageNumber = parseInt(props.location.pathname.split(paginationUrl)[1]);
 
             if(pageNumber > 1) {
                 
                 if(pageNumber*paginationStep-totalData >= paginationStep) pageNumber = 1;
 
             }
 
             if(pageNumber <= 0) pageNumber = 1;
 
             if(isNaN(pageNumber)) pageNumber = 1;
 
             setPaginationCurrent(pageNumber);
 
             let paginationOptions = {
                 limit: paginationStep,
                 skip: (pageNumber-1)*paginationStep,
             }
 
 
             //const data = await getYoutubeService();
             const data = await getYoutubePaginationService(paginationOptions);
 
             // END Pagination


            data.forEach(function(notification) {
                notification.bodyTruncated = (notification.body.length > characterLimit ? notification.body.substring(0, characterLimit) + '...' : notification.body);
            });


            setNotification(data);
            */

            const data = await getHiddenVideosService();

            //console.log("VIDEOS", data.reverse());

            setVideos(data);


        } catch (err) {
            console.log(err);
        }

        setVideosFetching(false);
    };

    const createHiddenVideo = async (values) => {
        try {
            setSubmitVideoLoading(true);
            let videoData = {
                videoData: { 
                    id: values.id, 
                    snippet: { 
                        title: values.title, 
                        description: values.description 
                    }
                }
            }
            const data = await createHiddenVideosService(videoData);
            getVideos();
            setIsCreateVideoModalOpen(false);
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitVideoLoading(false);
            setIsCreateVideoModalOpen(false);
        }
    };

    return (
        <YoutubeComponent
            videos={videos}
            videosFetching={videosFetching}
            setVideosFetching={setVideosFetching}
            isManageVideoModalOpen={isManageVideoModalOpen}
            setIsManageVideoModalOpen={setIsManageVideoModalOpen}
            submitVideoLoading={submitVideoLoading} 
            setSubmitVideoLoading={setSubmitVideoLoading}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            createHiddenVideo={createHiddenVideo}
            isCreateVideoModalOpen={isCreateVideoModalOpen}
            setIsCreateVideoModalOpen={setIsCreateVideoModalOpen}
        />
    );
};

export default Youtube;
